import React from 'react';
import PropTypes from 'prop-types';
import Richtext from '../Richtext/Richtext';
import BrandLogo from '../../../static/images/d1-logo-white.png';

// ====

const Document = (props) => {
  const { title, copy, items } = props;
  let showTable = true;

  return (
    <article className="w-100 brand-screen brand-screen-natural">
      <div className="w-100">
        <div className="container p-5">
          <div className="row justify-content-center">
            <div className="col-12 text-white">
              <p className="text-center mb-5">
                <a href="/">
                  <img
                    src={BrandLogo}
                    alt="Day1 Games"
                    width="100px"
                    className="border-0"
                  />
                </a>
              </p>
              <section className="mb-5 brand-fineprint">
                {title && (
                  <h1 className="brand-font text-center mb-5">{title}</h1>
                )}
                {copy && <Richtext content={copy} />}
              </section>

              {items && (
                <>
                  {items.map((node) => {
                    return (
                      <section className="mb-5 brand-fineprint">
                        {node.id && <a id={node.id}></a>}
                        {node.title && (
                          <h3 className="brand-font mb-3">{node.title}</h3>
                        )}
                        {node.copy && <Richtext content={node.copy} />}
                        {node.table.thead.slice(0, 1).map((th) => (
                          <>
                            {th.value == 'Head1'
                              ? (showTable = false)
                              : (showTable = true)}
                          </>
                        ))}
                        {node.table && showTable === true && (
                          <table className="table brand-text-white border-1">
                            <thead>
                              <tr>
                                {node.table.thead &&
                                  node.table.thead.map((th) => (
                                    <th scope="col">{th.value}</th>
                                  ))}
                              </tr>
                            </thead>
                            <tbody>
                              {node.table.tbody &&
                                node.table.tbody.map((tr) => (
                                  <tr>
                                    {tr.body.map((td) => (
                                      <td>
                                        {new RegExp('^https?://').test(
                                          td.value,
                                        ) ? (
                                          <a
                                            href={td.value}
                                            target="_blank"
                                            rel="noreferrer noopener"
                                          >
                                            {td.value}
                                          </a>
                                        ) : (
                                          td.value
                                        )}
                                      </td>
                                    ))}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        )}
                      </section>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Document;

// ====

Document.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  copy: PropTypes.shape,
};

Document.defaultProps = {
  id: null,
  copy: null,
};
