import React from 'react';
import Layout from '../components/Layout/Layout';
import Document from '../components/Screens/Document';

// ====

const Doc = (props) => {
  const { pageContext, blok } = props;

  let doc;
  if (pageContext) {
    doc = JSON.parse(pageContext.story.content);
  } else {
    doc = blok;
  }

  return (
    <Layout meta={doc.meta}>
      <Document title={doc.title} copy={doc.body} items={doc.content} />
    </Layout>
  );
};

export default Doc;
